import { replaceHash } from '@/services/mixins/playerMixins'

export default {
  getIsLoading: state => state.isLoading,
  getPlayers: state => state.players.options,
  getDefaultPlayers: state => state.defaultPlayers,
  getPlayersSelectList: state => state.playersSelectList,
  getPlayer: state => state.player,
  getOptions: state => state.options,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

  getPlayerFloatOnScroll: state => state.player.floatOnScroll,
  getPlayerFloatBorderWidth: state => state.player.floatBorderWidth,
  getPlayerFloatBorderColor: state => state.player.floatBorderColor,

  getPlayerFloatOnMobile: state => state.player.floatOnMobile,
  getPlayerFloatRequiresInteraction: state => state.player.floatRequiresInteraction,
  getPlayerFloatBorder: state => state.player.floatBorder,
  getPlayerSharing: state => state.player.sharing,
  getPlayerFloatDismissible: state => state.player.floatDismissible,
  getPlayerFloatWidth: state => state.player.floatWidth,

  getPlayerLogoOn: state => state.player.logoOn,
  getPlayerLogoUrl: state => state.player.logoUrl,
  getPlayerLogoPath: state => state.player.logoPath,
  getPlayerLogoDefaultOpacity: state => state.player.logoDefaultOpacity,
  getPlayerLogoInactiveOpacity: state => state.player.logoInactiveOpacity,
  getPlayerLogoPositionOptionId: state => state.player.logoPositionOptionId,
  getPlayerLogoOnClickUrl: state => state.player.logoOnClickUrl,
  getPlayerLogoColor: state => state.player.elementsLogoColor,
  getPlayerGradientValue: state => state.options?.gradientDirections?.find(obj => obj.id === state.player.gradientDirectionOptionId)?.value,

  getPlayerAdScheduleId: state => state.player.adScheduleId,
  getPlayerAdvertising: state => state.player.advertising,

  getPlayerReleaseChannel: state => state.player.releaseChannel,
  getAllPlayerReleaseChannels: state => state.player.releaseChannels,
  getPlayerTypeId: state => state.player.playerTypeId,
  getPlayerPlayerSizeType: state => state.player.playerSizeType,
  getPlayerPreloadOption: state => state.player.preloadOption,
  getPlayerDefaultBandwidthEstimateOption: state => state.player.defaultBandwidthEstimateOption,
  getPlayerResponsiveSize: state => state.player.responsiveSize,
  getPlayerLogoPositionOption: state => state.player.logoPositionOption,
  getPlayerFloatPositionOption: state => state.player.floatPositionOption,
  getPlayerPlayerSocialNetworks: state => state.player.playerSocialNetworks,
  getPlayerPlayerSocialNetworkIds: state => state.player.audioSocialNetworkIds,
  getConfig(state) {
    return {
      // config: {
      configId: state.player.publicId,
      version: state.player.playerTypeId,
      size: {
        responsiveSize: +state.player.responsiveSize,
      },
      autoplay: state.player.autoPlay,
      thumbnail: state.player.thumbnailOn,
      showQualities: state.player.showQualities,
      readQualities: state.player.readQualities, // not implemented yet
      autostartOnViewable: {
        state: state.player.autoStartOnViewableOn,
        onMobile: state.player.autoStartOnViewableMobile,
        onData: state.player.autoStartOnViewableOnData,
        percentageViewable: state.player.autoStartOnViewablePercentage,
      },
      autopauseOnViewable: {
        state: state.player.autoPauseOnViewableOn,
        percentageViewable: state.player.autoPauseOnViewablePercentage,
      },
      autopauseOnCuepoint: {
        state: state.player.autoPauseOnCuePointState,
        onDesktop: state.player.autoPauseOnCuePointOnDesktop,
        onMobile: state.player.autoPauseOnCuePointOnMobile,
        continueUnmuted: state.player.autoPauseOnCuePointContinueUnMuted,
        timeToPause: state.player.autoPauseOnCuePointTimeToPause,
        svgFile: state.player.autoPauseOnCuePointPlayButtonUrl,
      },
      showPlaylistItemProgress: state.player.showPlaylistItemProgress,
      showPlaylist: state.player.showPlaylist,
      pauseOtherAudios: state.player.pauseOtherAudios,
      focusOnAutostart: state.player.focusOnAutoStart,
      volume: state.player.volume,
      debug: state.player.debug,
      loop: state.player.loopContent,
      highlightCurrentAudio: state.player.highlightCurrentAudio,
      onHoverPlay: state.player.onHoverPlay,
      socialMedia: state.player?.playerSocialNetworks.map(x => x.name),
      sharing: state.player.sharing,
      float: {
        state: state.player.floatOnScroll,
        onMobile: state.player.floatOnMobile,
        position: state.player.floatPositionOption.value,
        dismissible: state.player.floatDismissible,
        // style: {
        //   width: state.player.floatWidth,
        //   border: `${state.player.floatBorderWidth}px solid ${state.player.floatBorderColor}`,
        // },
        requiresInteraction: state.player.floatRequiresInteraction,
      },
      playbackRates: state.player.enablePlayBackRateControls ? [0.25, 0.5, 0.75, 1, 2, 4] : [],
      lang: {
        locale: 'en',
        controls: {
          back: state.player.Back,
          play: state.player.Play,
          pause: state.player.Pause,
          next: state.player.Next,
          replay: state.player.Replay,
          volumeText: state.player.VolumeText,
          mute: state.player.Mute,
          unmute: state.player.UnMute,
          settings: state.player.Settings,
          chromecast: state.player.Chromecast,
          airplay: state.player.Airplay,
          playlistTitle: state.player.PlaylistText,
        },
        settings: {
          quality: state.player.Quality,
          autoplay: state.player.Autoplay,
          playbackSpeed: state.player.PlaybackSpeed,
          auto: state.player.Auto,
          off: state.player.Off,
          on: state.player.On,
          normal: state.player.Normal,
          share: state.player.Share,
        },
      },
      skin: {
        background: {
          useThumbnail: state.player.backgroundUseThumbnail,
          url: state.player.backgroundUrl,
          blur: state.player.backgroundBlur,
          primaryColor: state.player.backgroundPrimaryColor,
          gradient: state.player.backgroundGradient,
          secondaryColor: state.player.backgroundSecondaryColor,
          gradientDirection: state.options?.gradientDirections?.find(obj => obj.id === state.player.gradientDirectionOptionId)?.value,
        },
        border: {
          color: state.player.backgroundBorderColor,
          width: state.player.backgroundBorderWidth,
        },
        menus: {
          background: state.player.menuBackgroundDefaultColor,
          text: state.player.menuTextDefaultColor,
          toggle: {
            default: state.player.menuToggleDefault,
            off: state.player.menuToggleOffColor,
            on: state.player.menuToggleOnColor,
          },
        },
        playlist: {
          backgroundColor: state.player.elementsPlaylistColor,
          itemsProgressColor: state.player.elementsItemColor,
        },
        logo: {
          state: state.player.logoOn,
          file: state.player.logoUrl,
          onclickURL: state.player.logoOnClickUrl,
          color: state.player.elementsLogoColor,
        },
        slider: {
          state: true,
          rail: state.player.elementsSliderRailColor,
          progress: state.player.elementsSliderProgressBarColor,
          dragger: state.player.elementsDraggerColor,
        },
        text: {
          default: state.player.elementsDefaultColor,
          description: state.player.elementsDescriptionColor,
          author: state.player.elementsAuthorColor,
          title: state.player.elementsTitleColor,
        },
        componentColors: {
          primaryColor: state.player.elementsPrimaryColor,
          secondaryColor: state.player.elementsSecondaryColor,
        },
        buttons: {
          playbackButton: {
            state: state.player.elementsPlaybackButtonOn,
            color: state.player.elementsPlaybackButtonColor,
          },
          volumeButton: {
            state: state.player.elementsVolumeButtonOn,
            color: state.player.elementsVolumeButtonColor,
          },
          skipButtons: {
            state: state.player.elementsSkipButtonOn,
            color: state.player.elementsSkipButtonColor,
          },
          settingsButton: {
            state: state.player.elementsSettingsButtonOn,
            color: state.player.elementsSettingsButtonColor,
          },
          nextBackButtons: {
            state: state.player.elementsNextPrevButtonOn,
            color: state.player.elementsNextPrevButtonColor,
          },
          playlistButton: {
            state: state.player.elementsListButtonOn,
            color: state.player.elementsListButtonColor,
          },
        },
        elements: {
          timeInfo: {
            state: state.player.elementsTimeInfoOn,
            color: state.player.elementsTimeInfoColor,
          },
          dividers: {
            state: state.player.elementsDividersOn,
            color: state.player.elementsDividersColor,
          },
        },
      },
      // },
    }
  },
  getEndpointObject(state) {
    const {
      backgroundPrimaryColor,
      backgroundSecondaryColor,
      backgroundBorderColor,
      elementsPrimaryColor,
      elementsSecondaryColor,
      elementsPlaylistColor,
      elementsPlaybackButtonColor,
      elementsVolumeButtonColor,
      elementsSkipButtonColor,
      elementsSettingsButtonColor,
      elementsNextPrevButtonColor,
      elementsListButtonColor,
      elementsTimeInfoColor,
      elementsTitleColor,
      elementsAuthorColor,
      elementsDescriptionColor,
      elementsDefaultColor,
      elementsDividersColor,
      elementsSliderRailColor,
      elementsSliderProgressBarColor,
      elementsDraggerColor,
      elementsItemColor,
      menuBackgroundDefaultColor,
      menuTextDefaultColor,
      menuToggleDefaultColor,
      menuToggleOnColor,
      menuToggleOffColor,
      ...player
    } = state.player
    return {
      ...player,
      // controlBarDurationColor: playerMixins.default.methods.replaceHash(state.player.),
      backgroundPrimaryColor: replaceHash(backgroundPrimaryColor),
      backgroundSecondaryColor: replaceHash(backgroundSecondaryColor),
      backgroundBorderColor: replaceHash(backgroundBorderColor),
      elementsPrimaryColor: replaceHash(elementsPrimaryColor),
      elementsSecondaryColor: replaceHash(elementsSecondaryColor),
      elementsPlaylistColor: replaceHash(elementsPlaylistColor),
      elementsPlaybackButtonColor: replaceHash(elementsPlaybackButtonColor),
      elementsVolumeButtonColor: replaceHash(elementsVolumeButtonColor),
      elementsSkipButtonColor: replaceHash(elementsSkipButtonColor),
      elementsSettingsButtonColor: replaceHash(elementsSettingsButtonColor),
      elementsNextPrevButtonColor: replaceHash(elementsNextPrevButtonColor),
      elementsListButtonColor: replaceHash(elementsListButtonColor),
      elementsTimeInfoColor: replaceHash(elementsTimeInfoColor),
      elementsTitleColor: replaceHash(elementsTitleColor),
      elementsAuthorColor: replaceHash(elementsAuthorColor),
      elementsDescriptionColor: replaceHash(elementsDescriptionColor),
      elementsDefaultColor: replaceHash(elementsDefaultColor),
      elementsDividersColor: replaceHash(elementsDividersColor),
      elementsSliderRailColor: replaceHash(elementsSliderRailColor),
      elementsSliderProgressBarColor: replaceHash(elementsSliderProgressBarColor),
      elementsDraggerColor: replaceHash(elementsDraggerColor),
      elementsItemColor: replaceHash(elementsItemColor),
      menuBackgroundDefaultColor: replaceHash(menuBackgroundDefaultColor),
      menuTextDefaultColor: replaceHash(menuTextDefaultColor),
      menuToggleDefaultColor: replaceHash(menuToggleDefaultColor),
      menuToggleOnColor: replaceHash(menuToggleOnColor),
      menuToggleOffColor: replaceHash(menuToggleOffColor),
    }
  },
  getCustomizeColorsObject() {
    return [
      {
        name: 'Menus',
        id: 'menu',
        elements: [
          {
            name: 'Background',
            colors: [
              {
                label: 'Default',
                prop: 'menuBackgroundDefaultColor',
              },
            ],
          },
          {
            name: 'Text',
            colors: [
              {
                label: 'Default',
                prop: 'menuTextDefaultColor',
              },
            ],
          },
          {
            name: 'Toggle',
            colors: [
              {
                label: 'Default',
                prop: 'menuToggleDefaultColor',
              },
              {
                label: 'On',
                prop: 'menuToggleOnColor',
              },
              {
                label: 'Off',
                prop: 'menuToggleOffColor',
              },
            ],
          },
        ],
      },
      {
        name: 'Elements',
        id: 'elements',
        elements: [
          {
            name: 'Colors',
            colors: [
              {
                label: 'Primary Color',
                prop: 'elementsPrimaryColor',
              },
              {
                label: 'Secondary Color',
                prop: 'elementsSecondaryColor',
              },
            ],
          },
          {
            name: 'Buttons',
            colors: [
              {
                label: 'Playback',
                prop: 'elementsPlaybackButtonColor',
              },
              {
                label: 'Volume',
                prop: 'elementsVolumeButtonColor',
              },
              {
                label: 'Skip',
                prop: 'elementsSkipButtonColor',
              },
              {
                label: 'Settings',
                prop: 'elementsSettingsButtonColor',
              },
              {
                label: 'Next and Back',
                prop: 'elementsNextPrevButtonColor',
              },
            ],
          },
          {
            name: 'Playlist',
            colors: [
              {
                label: 'Background',
                prop: 'elementsPlaylistColor',
              },
              {
                label: 'Button',
                prop: 'elementsListButtonColor',
              },
              {
                label: 'Item Progress',
                prop: 'elementsItemColor',
              },
            ],
          },
          {
            name: 'Time Info',
            colors: [
              {
                label: 'Default',
                prop: 'elementsTimeInfoColor',
              },
            ],
          },
          {
            name: 'Title',
            colors: [
              {
                label: 'Default',
                prop: 'elementsTitleColor',
              },
            ],
          },
          {
            name: 'Author',
            colors: [
              {
                label: 'Default',
                prop: 'elementsAuthorColor',
              },
            ],
          },
          {
            name: 'Description',
            colors: [
              {
                label: 'Default',
                prop: 'elementsDescriptionColor',
              },
            ],
          },
          {
            name: 'Default Text',
            colors: [
              {
                label: 'Default',
                prop: 'elementsDefaultColor',
              },
            ],
          },
          {
            name: 'Dividers',
            colors: [
              {
                label: 'Default',
                prop: 'elementsDividersColor',
              },
            ],
          },
          {
            name: 'Slider',
            colors: [
              {
                label: 'Rail',
                prop: 'elementsSliderRailColor',
              },
              {
                label: 'Progressbar',
                prop: 'elementsSliderProgressBarColor',
              },
              {
                label: 'Dragger',
                prop: 'elementsDraggerColor',
              },
            ],
          },
        ],
      },
    ]
  },
  getCheckboxes() {
    return [
      {
        label: 'Show thumbnail',
        prop: 'thumbnailOn',
      },
      {
        label: 'Show volume button',
        prop: 'elementsVolumeButtonOn',
      },
      {
        label: 'Show next/back button',
        prop: 'elementsNextPrevButtonOn',
      },
      {
        label: 'Show +- 15sec button',
        prop: 'elementsSkipButtonOn',
      },
      {
        label: 'Show 3 dots button',
        prop: 'elementsSettingsButtonOn',
      },
      {
        label: 'Show playback rates',
        prop: 'enablePlayBackRateControls',
      },
      {
        label: 'Show qualities',
        prop: 'showQualities',
      },
      {
        label: 'Show airplay button',
        prop: 'airplayButton',
      },
      {
        label: 'Show playlist button',
        prop: 'elementsListButtonOn',
      },
    ]
  },
  getAdvancedSettingsMobileCheckboxes() {
    return [
      {
        label: 'Pause when related is shown',
        prop: 'pauseWhenRelatedIsShown',
      },
      {
        label: 'Read qualities',
        prop: 'readQualities',
      },
      {
        label: 'Enable gravity change',
        prop: 'enableGravityChange',
      },
      {
        label: 'Force sound',
        prop: 'forceSound',
      },
      {
        label: 'Enable haptic feedback on button click',
        prop: 'enableHapticFeedbackOnButtonClick',
      },
      {
        label: 'Enable haptic feedback on pinch to zoom',
        prop: 'enableHapticFeedbackOnPinchToZoom',
      },
    ]
  },
  getLocalizations() {
    return [
      {
        name: 'Player Controls',
        id: 'playerControls',
        items: [
          'Play',
          'Pause',
          'Next',
          'Back',
          'Replay',
          'VolumeText',
          'Mute',
          'UnMute',
          'PlaybackSpeed',
          'Playlist',
          'Airplay',
          'Settings',
        ],
      },
      {
        name: 'More Player Options',
        id: 'morePlayerOptions',
        items: [
          'Autoplay',
          'Normal',
          'Quality',
          'Auto',
          'On',
          'Off',
          'Share',
        ],
      },
    ]
  },

}
