import axios from '../libs/axios'

export const state = {
  isHidden: false,
  isUploading: false,
  uploads: [],
  FILE_CHUNK_SIZE: 50000000, // 50000000 = 50MB
  projectId: '',
  eventBus: {},
  eventBusName: '',
}
export const mutations = {
  SET_PROJECT_ID: projectId => { state.projectId = projectId },
  SET_EVENT_BUS: eventBus => { state.eventBus = eventBus },
  SET_EVENT_BUS_NAME: eventBusName => { state.eventBusName = eventBusName },
  UPDATE_IS_UPLOADING: v => { state.isUploading = v },
  ADD_NEW_UPLOADING_FILE: file => { state.uploads.unshift(file) },
  ADD_REUPLOADING_FILE: ({ FILE, index }) => { state.uploads.splice(index, 1, FILE) },
  CLEAR_UPLOADS: () => { state.uploads = [] },
  DELETE_OBJECT_FROM_LIST: index => { state.uploads.splice(index, 1) },
}

export const getters = {
  getUploads: () => state.uploads,
  getUploadIsUploading: () => state.isUploading,
  getActiveUploads: () => state.uploads.filter(x => x.status === 'uploading').length,
  getSuccessUploads: () => state.uploads.filter(x => x.status === 'success').length,
  getSuccessAndReencodedUploads: () => state.uploads.filter(x => x.status === 'success').length + state.uploads.filter(x => x.status === 'reencoded@success').length,
  getFailedUploads: () => state.uploads.filter(x => x.status === 'failed').length,
}

function generateUrl(url = '') {
  return `/api/projects/${state.projectId}/${url}`
}

function generateAudioUrl(url = '') {
  return `${process.env.VUE_APP_API_AUDIO}api/v1/${state.projectId}/${url}`
}

export const actions = {
  getPreSigned(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(generateUrl('uploads/url'), data.params, {
          headers: data.headers,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getMultipartPreSigned(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(generateUrl('uploads/multipart'), data, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getMultipartUploadedParts(requestKey) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${generateUrl('uploads/url/multipart')}/${requestKey}/parts`, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  multipartCompleteAction(data) {
    return new Promise((resolve, reject) => {
      axios
        .put(generateUrl('uploads/multipart-complete'), data, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  multipartAbort(requestKey) {
    return new Promise((resolve, reject) => {
      axios
        .put(generateUrl('uploads/multipart-abort'), { requestKey }, this.customHeaders)
        .then(response => {
          state.eventBus.$emit(state.eventBusName, state.uploads)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  encodesVideo(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(generateUrl('encodes'), { ...data }, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  reencodesVideo(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(generateUrl(`encodes/${id}`), { ...data }, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  encodesAudio(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(generateAudioUrl('audios'), { ...data }, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  reencodesAudio(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(generateAudioUrl(`audios/${id}`), { ...data }, this.customHeaders)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
