/* eslint-disable no-plusplus */
import moment from 'moment'

export default {
  methods: {
    shortBigNumber(inputNumber, divider, nToFixed) {
      const units = ['', 'K', 'M', 'B', 'T', 'Q']
      inputNumber /= divider

      let n = parseInt(inputNumber, 10) || 0
      let l = 0

      while (n >= 1000 && ++l) {
        n /= 1000
      }

      return (`${l > 0 ? n.toFixed(nToFixed) : n}${units[l]}`)
    },
    niceBytes(bytes) {
      if (bytes === 0) {
        return 0
      }
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      let l = 0; let
        n = parseInt(bytes, 10) || 0

      while (n >= 1024 && ++l) {
        n /= 1024
      }

      return (`${n.toFixed(2)}${units[l]}`)
    },
    secondToTime(seconds) {
      const units = ['seconds', 'minutes', 'hours']

      let l = 0
      let n = parseInt(seconds, 10) || 0

      while (n >= 60 && ++l) {
        n /= 60
      }
      return (`${n.toFixed(parseInt((seconds / (l * 60)).toFixed(), 10) === n ? 0 : 2)} ${units[l]}`)
    },
    secondToTimeFormated(seconds) {
      const res = moment.utc(seconds * 1000).format(seconds > 3600 ? 'HH:mm:ss' : 'mm:ss')

      return res
    },
    getHoursFromSeconds(seconds) {
      return parseInt(((seconds / 60) / 60), 10)
    },
    getMinutesFromSeconds(seconds) {
      return parseInt(((seconds / 60) % 60), 10)
    },
    getSecondsFromSeconds(seconds) {
      return parseInt((seconds % 60), 10)
    },
    numberWithCommas(num) {
      return Number(num).toLocaleString('en-US')
    },
    secondsToMinutes(seconds) {
      if (!seconds) {
        return 0
      }
      // Convert seconds to minutes
      const minutes = seconds / 60
      // Round to two decimal places
      const roundedMinutes = Math.round(minutes * 100) / 100
      return roundedMinutes
    },
    formatNumber(num) {
      const absNum = Math.abs(num)
      const ranges = ['', 'K', 'M', 'B']
      let rangeIndex = 0
      let formattedNum = absNum

      while (formattedNum >= 1000) {
        formattedNum /= 1000
        rangeIndex++
      }

      if (rangeIndex > 0) {
        if (formattedNum % 1 !== 0) {
          // eslint-disable-next-line no-mixed-operators
          formattedNum = formattedNum.toFixed(2)
        } else {
          formattedNum = Math.round(formattedNum)
        }
        return (num < 0 ? '-' : '') + formattedNum + ranges[rangeIndex]
      }

      return num?.toString()
    },

    formatTime(seconds, showUnit) {
      if (seconds === null || seconds === undefined) {
        return 'N/A' // Return a default value for null or undefined input
      }
      if (seconds < 1) {
        return 0
      }
      if (seconds < 60) {
        return `00:${seconds.toFixed()} ${showUnit ? 'sec' : ''}`
      }
      if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `00:${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds.toFixed()} ${showUnit ? 'min' : ''}`
      }
      const hours = Math.floor(seconds / 3600)
      const remainingMinutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = seconds % 60
      let formattedTime = `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`
      formattedTime += `:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds.toFixed()} ${showUnit ? 'h' : ''}`
      return formattedTime
    },

    convertSecondsToMinutes(secondsArray) {
      if (!Array.isArray(secondsArray)) {
        throw new Error('Input is not an array.')
      }

      const resultArray = secondsArray.map(seconds => {
        if (seconds < 60) {
          return `00:${this.pad(seconds, 2)}`
        }
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${this.pad(minutes, 2)}:${this.pad(remainingSeconds, 2)}`
      })

      return resultArray
    },

    pad(num, size) {
      let s = `${num }`
      while (s.length < size) s = `0${ s}`
      return s
    },

    timeSince(dateAccepted) {
      const currentDate = new Date()
      const acceptedDate = new Date(dateAccepted)
      const elapsedMilliseconds = currentDate - acceptedDate
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)

      if (
        currentDate.getFullYear() === acceptedDate.getFullYear()
          && currentDate.getMonth() === acceptedDate.getMonth()
          && currentDate.getDate() === acceptedDate.getDate()
      ) {
        return 'Today'
      }

      const timeUnits = [
        { unit: 'year', seconds: 31536000 },
        { unit: 'month', seconds: 2592000 },
        { unit: 'day', seconds: 86400 },
        { unit: 'hour', seconds: 3600 },
        { unit: 'min', seconds: 60 },
      ]

      const result = timeUnits.find(({ seconds }) => elapsedSeconds >= seconds)

      if (result) {
        const { unit, seconds } = result
        const interval = Math.floor(elapsedSeconds / seconds)
        return `${interval} ${unit}${interval !== 1 ? 's' : ''} ago`
      }

      return `${elapsedSeconds} sec ago`
    },

    timeSinceArray(datesArray) {
      const timeUnits = [
        { unit: 'year', seconds: 31536000 },
        { unit: 'month', seconds: 2592000 },
        { unit: 'day', seconds: 86400 },
        { unit: 'hour', seconds: 3600 },
        { unit: 'min', seconds: 60 },
      ]

      const results = datesArray?.map(dateAccepted => {
        const currentDate = new Date()
        const acceptedDate = new Date(dateAccepted)

        const elapsedMilliseconds = currentDate - acceptedDate
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)

        const result = timeUnits.find(({ seconds }) => elapsedSeconds >= seconds)

        if (result) {
          const { unit, seconds } = result
          const interval = Math.floor(elapsedSeconds / seconds)
          if (interval === 1) {
            return `1 ${unit} ago`
          }
          return `${interval} ${unit}s ago`
        }

        return `${elapsedSeconds} sec ago`
      })

      return results
    },

    calculateTimeDifference(timestamps) {
      const currentTime = new Date()
      return timestamps.map(timestamp => {
        const [hours, minutes, seconds] = timestamp.split(':')
        const timestampDate = new Date()
        timestampDate.setHours(hours, minutes, seconds)
        const difference = currentTime - timestampDate
        const differenceInMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        if (differenceInMinutes === 0) {
          return 'now'
        }
        const unit = differenceInMinutes === 1 ? 'min' : 'mins'
        return `${differenceInMinutes} ${unit} ago`
      })
    },
    convertCamelCaseToWords(inputString) {
      const words = [inputString[0]]
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < inputString.length; i++) {
        if (inputString[i] === inputString[i].toUpperCase()) {
          words.push(' ')
        }
        words.push(inputString[i])
      }
      return words.join('')
    },
    processViewers(liveViewers) {
      const uniqueViewers = []
      const hoursList = this.generateHoursList()

      const uniqueViewerslist = liveViewers.length > 0 ? liveViewers[0].uniqueViewers : []

      if (uniqueViewerslist) {
        uniqueViewers.push(...uniqueViewerslist)
      }

      this.fillRemainingUniqueViewers(uniqueViewers)

      liveViewers.forEach(viewer => {
        this.findHourIndex(viewer.date, hoursList)
      })

      return {
        uniqueViewers,
        hoursList,
      }
    },
    generateHoursList() {
      const currentTime = new Date()
      return Array.from({ length: 25 }, (_, i) => {
        const hourAgo = new Date(currentTime - i * 60 * 60 * 1000)
        return hourAgo
      })
    },
    fillRemainingUniqueViewers(uniqueViewers) {
      while (uniqueViewers.length < 24) {
        uniqueViewers.push(0)
      }
    },
    findHourIndex(viewerDate, hoursList) {
      const currentTime = new Date(viewerDate)
      const nextHour = new Date(currentTime.getTime() + 60 * 60 * 1000)
      return hoursList.findIndex(time => viewerDate >= new Date(time) && viewerDate < nextHour)
    },

  },
}
