/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import project from '@/store/account/project/moduleProject'

const baseURL = process.env.VUE_APP_API_AUDIO
const hostURL = process.env.VUE_APP_API_HOST
const analyticsUrl = process.env.VUE_APP_API_ANALYTICS_AUDIO

export default {
  getAudio({ commit, state }, id) {
    commit('LOADING_AUDIO', true)
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/audios/${id}`)
        .then(async response => {
          commit('SET_AUDIO', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => {
          commit('LOADING_AUDIO', false)
        })
    })
  },
  getAudios({ commit, state }) {
    commit('LOADING_AUDIOS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/audios`, state.filters)
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_AUDIOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => {
          commit('LOADING_AUDIOS', false)
        })
    })
  },
  getAudioAssets({ commit, state }, audioId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/audios/${audioId}/assets`, {
          params: {
            anyStatus: true,
          },
        })
        .then(async response => {
          commit('SET_AUDIO_STREAMS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  reencodesAudio({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}api/v1/${project.state.activeProjectId}/audios/${id}`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  uploadAudioThumbnail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}api/v1/${project.state.activeProjectId}/audios/thumbnail/${payload.audioId}`, payload.file,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }).then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  putAudio({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v1/${project.state.activeProjectId}/audios`,
        baseURL,
        method: 'PUT',
        data,
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteAudios({ commit }, items) {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseURL}api/v1/${project.state.activeProjectId}/audios`, { data: { ids: items } })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getEmbed({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${hostURL}audio/embed/${data.player}/${data.id}/${data.seo}`)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAudioPodcasts({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/audios/${id}/podcasts`)
        .then(async response => {
          commit('SET_AUDIO_PODCASTS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // Analytics
  getAudioAnalytics({ commit, state }, query) {
    const { audioId, data } = query
    let endpoint = ''
    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/graph?metric=${data.metric}&intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/graph?metric=${data.metric}&audioId=${audioId}&intervalType=${data.intervalType}`
    }
    let differenceInDays = null
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      commit('LOADING_CUSTOM_ANALYTICS', true)
      const [from, to] = state.dateStr.split(' to ')
      const utcDates = data.customDate.map(dateString => new Date(dateString))
      const startDate = utcDates[0]
      const endDate = utcDates[1]
      const differenceinTime = endDate.getTime() - startDate.getTime()
      differenceInDays = differenceinTime / (1000 * 3600 * 24)
      differenceInDays = 5
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS', true)

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(response => {
          const result = response?.data?.result
          commit('SET_ANALYTICS', { result, intervalType: query.data.intervalType, differenceInDays })
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS', [])
          commit('LOADING_ANALYTICS', false)
          commit('LOADING_CUSTOM_ANALYTICS', false)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS', false)
          commit('LOADING_CUSTOM_ANALYTICS', false)
        })
    })
  },

  getAudioAnalyticsTopCountries({ commit, state }, query) {
    const { audioId, data } = query
    let endpoint = ''

    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topCountries?intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topCountries?audioId=${audioId}&intervalType=${data.intervalType}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_COUNTRIES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_COUNTRIES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_COUNTRIES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_COUNTRIES', false)
        })
    })
  },

  getTopBrowsersGroupedData({ commit, state }, query) {
    const { audioId, data } = query
    let endpoint = ''

    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topBrowsers?intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topBrowsers?audioId=${audioId}&intervalType=${data.intervalType}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_BROWSERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_BROWSERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_BROWSERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_BROWSERS', false)
        })
    })
  },

  getAudioAnalyticsTopPlatforms({ commit, state }, query) {
    const { audioId, data } = query

    let endpoint = ''
    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topPlatforms?intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topPlatforms?audioId=${audioId}&intervalType=${data.intervalType}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_PLATFORMS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_PLATFORMS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_PLATFORMS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_PLATFORMS', false)
        })
    })
  },

  getAudioAnalyticsTopDevices({ commit, state }, query) {
    const { audioId, data } = query

    let endpoint = ''
    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topDevices?intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topDevices?audioId=${audioId}&intervalType=${data.intervalType}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_DEVICES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_DEVICES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_DEVICES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_DEVICES', false)
        })
    })
  },

  getAudioAnalyticsLiveViewrs({ commit, state }, query) {
    const { audioId, data } = query
    let endpoint = ''

    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/liveViewers`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/liveViewers?audioId=${audioId}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_LIVE_VIEWERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_LIVE_VIEWERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_LIVE_VIEWERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_LIVE_VIEWERS', false)
        })
    })
  },

  getAudioAnalyticsKeyMoments({ commit, state }, query) {
    const { audioId, data } = query

    let endpoint = ''
    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/keyMoments/graph?metric=${data.metric}&intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/keyMoments/graph?audioId=${audioId}&metric=${data.metric}&intervalType=${data.intervalType}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_KEY_MOMENTS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_KEY_MOMENTS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_KEY_MOMENTS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_KEY_MOMENTS', false)
        })
    })
  },

  getAudioAnalyticsKeyMomentsCards({ commit, state }, query) {
    const { audioId, data } = query

    let endpoint = ''
    if (audioId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/keyMoments/cards?metric=${data.metric}&intervalType=${data.intervalType}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/keyMoments/cards?audioId=${audioId}&metric=${data.metric}&intervalType=${data.intervalType}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_KEY_MOMENTS_CARDS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_KEY_MOMENTS_CARDS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_KEY_MOMENTS_CARDS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_KEY_MOMENTS_CARDS', false)
        })
    })
  },

  getAudioAnalyticsReport({ commit, state }, query) {
    const { audioId, data } = query
    let endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/download/xls/?audioId=${audioId}&intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_REPORT', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_ANALYTICS_REPORT', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS_REPORT', null)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_REPORT', false)
        })
    })
  },
}
