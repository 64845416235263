/* eslint-disable no-unused-vars */
export default [

  // Live Channels Routing
  {
    path: '/:projectId/channels',
    name: 'live-channels',
    component: () => import('@/views/live/channels/Channels.vue'),
    meta: {
      titleText: 'Channels',
      parentText: 'Dashboard',
      contentClass: 'channels',
      navActiveLink: 'live-channels',
      gjIcon: 'Livestream - main',
      action: 'read',
      resource: 'live-channel',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Channels',
          to: 'live-channels',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:projectId/channels/add',
    name: 'live-channels-add',
    component: () => import('@/views/live/channels/Add.vue'),
    meta: {
      titleText: 'Channels',
      parentText: 'Dashboard',
      contentClass: 'channel',
      navActiveLink: 'live-channels',
      gjIcon: 'Livestream - main',
      action: 'write',
      resource: 'live-channel',
      projectRequired: true,
      breadcrumb: [
        { text: 'Channels', to: 'live-channels' },
        { text: 'Create Live Channel', active: true },
      ],
    },
  },
  {
    path: '/:projectId/channels/:publicId',
    name: 'live-channels-edit',
    component: () => import('@/views/live/channels/Edit.vue'),
    meta: {
      title: '',
      titleText: 'Channels',
      parentText: 'Dashboard',
      contentClass: 'channel',
      navActiveLink: 'live-channels',
      gjIcon: 'Livestream - main',
      action: 'read',
      resource: 'live-channel',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Channels',
          to: 'live-channels',
        },
        {
          dynamic: true,
          text: 'Edit Channel',
          active: true,
        },
      ],
    },
  },

  // Live Videos Routing

  {
    path: '/:projectId/live-videos',
    name: 'live-videos',
    component: () => import('@/views/live/live-videos/Lives.vue'),
    meta: {
      titleText: 'Live Videos',
      parentText: 'Dashboard',
      contentClass: 'live-videos',
      navActiveLink: 'live-videos',
      gjIcon: 'Livestream - main',
      action: 'read',
      resource: 'live-video',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Live Videos',
          to: 'live-videos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:projectId/live-videos/add',
    name: 'live-videos-add',
    component: () => import('@/views/live/live-videos/Add.vue'),
    meta: {
      title: '',
      titleText: 'Live',
      parentText: 'Dashboard',
      contentClass: 'live-video',
      navActiveLink: 'live-videos',
      gjIcon: 'Livestream - main',
      action: 'write',
      resource: 'live-video',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Live Videos',
          to: 'live-videos-add',
        },
        {
          text: 'Add live videos',
          to: 'live-videos-add',
          active: true,
        },
      ],
    },
  },
  {
    path: '',
    component: () => import('@/views/live/live-videos/Edit.vue'),
    children: [
      {
        path: '/:projectId/live-videos/:id/customization',
        name: 'live-videos-customization',
        component: () => import('@/views/live/live-videos/tabs/customization/Customization.vue'),
        meta: {
          titleText: 'Live Videos',
          parentText: 'Dashboard',
          contentClass: 'live-videos-customization',
          navActiveLink: 'live-videos',
          gjIcon: 'Livestream - main',
          action: 'read',
          resource: 'live-video',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Live Videos',
              to: 'live-videos-customization',
              active: true,
            },
            { dynamic: true, text: 'Edit Video', active: true },
          ],
        },
      },

      {
        path: '/:projectId/live-videos/:id/analytics',
        name: 'live-videos-analytics',
        component: () => import('@/views/live/live-videos/tabs/analytics-tabs/Analytics.vue'),
        meta: {
          titleText: 'Live Videos',
          parentText: 'Dashboard',
          contentClass: 'live-videos-analytics',
          navActiveLink: 'live-videos',
          gjIcon: 'Livestream - main',
          action: 'read',
          resource: 'live-video',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Live Videos',
              to: 'live-videos-analytics',
              active: true,
            },
            { dynamic: true, text: 'Edit Video', active: true },
          ],
        },
      },

      // {
      //   path: '/:projectId/live-videos/:id/studio',
      //   name: 'live-videos-studio',
      //   component: () => import('@/views/live/live-videos/tabs/studio/Studio.vue'),
      //   meta: {
      //     titleText: 'Live Videos',
      //     parentText: 'Studio',
      //     contentClass: 'live-videos-studio',
      //     navActiveLink: 'live-videos',
      //     gjIcon: 'Livestream - main',
      //     action: 'read',
      //     resource: 'live-video',
      //     projectRequired: true,
      //     breadcrumb: [
      //       {
      //         text: 'Live Videos',
      //         to: 'live-videos-studio',
      //         active: true,
      //       },
      //       { dynamic: true, text: 'Edit Video', active: true },
      //     ],
      //   },
      // },

      {
        path: '/:projectId/live-videos/:id/:general?',
        name: 'live-videos-general-information',
        component: () => import('@/views/live/live-videos/tabs/GeneralInformationTab.vue'),
        beforeEnter: (to, from, next) => {
          const { general } = to.params
          const validGeneralValues = ['general', undefined]

          if (!validGeneralValues.includes(general)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          title: 'Live Videos',
          titleText: 'Live Videos',
          parentText: 'Dashboard',
          contentClass: 'live-video',
          navActiveLink: 'live-videos',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'live-video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Live Videos', to: 'live-videos-general-information' },
            { dynamic: true, text: 'Live Videos', active: true },
          ],
        },
      },
    ],
  },
]
