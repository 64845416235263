<template>
  <b-toast
    :id="toastId"
    :v-show="uploads"
    solid
    no-auto-hide
    no-close-button
    toaster="b-toaster-bottom-left"
    body-class="p-0"
    header-class="p-50 header bg-black text-white"
    :toast-class="!open ? 'toast-closed' : ''"
  >
    <template #toast-title>
      <div
        class="d-flex flex-grow-1 align-items-center justify-content-between text-white"
      >
        <span v-show="open">
          <p
            v-if="failedUploads > 0"
            class="text-sm m-0 text-truncate "
          >
            {{ failedUploads > 1 ? `${failedUploads} uploads failed` : `${failedUploads} upload failed` }}
          </p>
          <p
            v-else-if="activeUploads > 0"
            class="text-sm m-0 text-truncate"
          >
            {{ activeUploads > 1 ? `Uploading ${activeUploads} items` : `Uploading ${activeUploads} item` }}
          </p>
          <p
            v-else
            class="text-sm m-0 text-truncate"
          >
            {{ `${getSuccessAndReencodedUploads} of ${uploads.length} uploads completed` }}
          </p>
        </span>
        <span v-show="!open">
          <p
            v-if="failedUploads > 0"
            class="text-sm m-0 text-truncate"
          >
            {{ failedUploads > 1 ? `${failedUploads} failed` : `${failedUploads} failed` }}
          </p>
          <p
            v-else-if="activeUploads > 0"
            class="text-sm m-0 text-truncate"
          >
            {{ activeUploads > 1 ? `Uploading ${activeUploads} items` : `Uploading ${activeUploads} item` }}
          </p>
          <p
            v-else
            class="text-sm m-0 text-truncate"
          >
            {{ `${getSuccessAndReencodedUploads} of ${uploads.length} completed` }}
          </p>
        </span>
        <div class="d-flex">
          <button
            v-b-toggle.collapse-1
            type="button"
            class="m-0 close p-0 text-white"
            data-dismiss="toast"
            @click="() => { open = !open }"
          >
            <GjIcon
              v-show="open"
              name="Minus"
              color="text-white"
            />
            <GjIcon
              v-show="!open"
              name="Fullscreen"
              color="text-white"
            />
          </button>
          <button
            type="button"
            class="m-0 ml-50 close p-0 text-white"
            data-dismiss="toast"
            @click.prevent="closeToast"
          >
            <GjIcon
              name="Close"
              color="text-main"
            />
          </button>
        </div>
      </div>
    </template>
    <b-collapse
      id="collapse-1"
      visible
      class="p-0"
    >
      <VuePerfectScrollbar class="scroll-area">
        <b-list-group class=" p-0">
          <template v-for="(upload, i) in uploads">
            <b-list-group-item
              :key="i"
              class="d-flex justify-content-between align-items-center rounded-0 border-right-0 border-top-0 border-left-0 px-75 py-75"
              :class="uploads.length > 1 && uploads.length-1 > i ? 'border-bottom-light' : 'border-bottom-0'"
              @click="upload.status === 'success' && removeUploadFromArray(upload, i)"
            >
              <div class="col-11 p-0">
                <div class="d-flex">

                  <GjIcon
                    :name="upload.fileType === 'audio' ? 'IconparkMusic' : 'VideoSquare'"
                    size="24"
                    class="text-main p-75 custom-dimensions"
                  />

                  <div class="pl-50">
                    <p
                      class="text-sm text-main m-0 text-truncate text-main text-bold"
                      style="max-width:260px;"
                    >
                      {{ upload.name }}
                    </p>
                    <div
                      class="d-flex  justify-content-between gap-4"
                      style="width:260px;"
                    >

                      <p class="text-muted text-xsm text-primary text-gray text-600 text-weight-bold m-0 ">
                        {{ `${upload.uploadType !== 'url' ? ` ${niceBytes(upload.size)}  ` : ''} ` }}
                      </p>

                      <p
                        class="text-muted text-xsm text-gray text-600 text-weight-bold m-0"
                        :class="{ 'text-green': uploaded(upload) === 'Completed' }"
                      >
                        {{ ` ${uploaded(upload)}` }}
                      </p>

                    </div>
                  </div>
                </div>
                <div class="title mt-05">
                  <div class="d-flex flex-column mt-50">

                    <span v-if="upload.status === 'uploading'">
                      <b-progress
                        v-model="upload.progress"
                        height="3px"
                        max="100"
                      />

                    </span>
                    <span v-if="upload.status === 'success'">
                      <b-progress
                        value="100"
                        variant="success"
                        height="3px"
                        max="100"
                      />
                    </span>
                    <span v-if="upload.status === 'failed' ">
                      <b-progress
                        value="100"
                        variant="danger"
                        height="3px"
                        max="100"
                      />
                    </span>
                    <span v-if="upload.status === 'encodeFailed'">
                      <b-progress
                        value="100"
                        variant="danger"
                        height="3px"
                        max="100"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <b-button
                v-show="upload.status === 'uploading' || !upload.status"
                id="skip-intro-info-button"
                title="Cancel upload"
                variant="flat-dark"
                class="p-0 rounded-circle"
                style="height: 24px; cursor: pointer;"
                @click.stop="upload.cancelToken.cancel()"
              >

                <GjIcon
                  name="Close"
                  size="24"
                  class="text-main"
                />
              </b-button>
              <b-button
                v-show="upload.status === 'success'"
                id="skip-intro-info-button"
                title="Edit"
                variant="flat-dark"
                class="p-0 rounded-circle"
                style="height: 24px; cursor: pointer;"
              >
                <GjIcon
                  name="EditPenIcon"
                  size="24"
                  class="text-main"
                />
              </b-button>
              <b-button
                v-show="upload.status === 'failed' || upload.status === 'encodeFailed'"
                id="skip-intro-info-button"
                title="Retry upload"
                variant="flat-dark"
                class="p-0 rounded-circle"
                style="height: 24px; cursor: pointer;"
                @click.stop="retry(upload, i)"
              >
                <GjIcon
                  name="Refresh"
                  size="24"
                  class="text-main"
                />
              </b-button>
              <b-button
                v-show="upload.status === 'canceled'"
                id="skip-intro-info-button"
                title="Delete file from upload list"
                variant="flat-dark"
                class="p-0 rounded-circle"
                style="height: 24px; cursor: pointer;"
                @click.stop="deleteFileFromUploads(i)"
              >
                <GjIcon
                  name="Delete"
                  size="24"
                  class="text-main"
                />
              </b-button>
            </b-list-group-item>
          </template>
        </b-list-group>
      </VuePerfectScrollbar>
    </b-collapse>

    <!-- WARNING MODAL -->
    <warning-modal
      :embed-ref="cancelUploadsWarningRef"
      :title="`Cancel all uploads`"
      :description="`Your uploads are not complete. Would you like to cancel all ongoing uploads?`"
      :cancel-button="`Continue uploads`"
      :ok-button="`Cancel uploads`"
      @stopUploads="destroyToast()"
    />
  </b-toast>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import WarningModal from '@/components/modals/WarningModal.vue'
import upload, {
  mutations, getters,
} from '@/services/upload/uploadFile'
import converters from '@/services/mixins/converters'

export default {
  components: {
    VuePerfectScrollbar,
    'warning-modal': WarningModal,
  },
  mixins: [converters],
  data() {
    return {
      open: true,
      toastId: 'uploads-toast',
      cancelUploadsWarningRef: 'warning-uploads-cancel',
      uploads: [],
      activeUploads: 0,
      succeedUploads: 0,
      failedUploads: 0,
      getSuccessAndReencodedUploads: 0,
    }
  },
  watch: {
    succeedUploads(v) {
      if (v > 0) this.$store.state[`${this.$route.name}Module`].reloadPage = true
    },
    getSuccessAndReencodedUploads(v) {
      if (v > 0) this.$store.state[`${this.$route.name}Module`].newReencodeMedia = true
    },
  },
  created() {
    mutations.SET_EVENT_BUS(this.$root)
    mutations.SET_EVENT_BUS_NAME('uploadScriptEvent')
    this.fn = async v => {
      this.uploads = v
      this.activeUploads = getters.getActiveUploads()
      this.succeedUploads = getters.getSuccessUploads()
      this.getSuccessAndReencodedUploads = getters.getSuccessAndReencodedUploads()
      this.failedUploads = getters.getFailedUploads()
    }
    this.$root.$on('uploadScriptEvent', this.fn)
  },
  beforeDestroy() {
    upload.destroyUploads()
    this.$root.$off('uploadScriptEvent', this.fn)
  },
  methods: {
    deleteFileFromUploads(index) {
      if (this.uploads.length - 1 < 1) this.$bvToast.hide(this.toastId)
      mutations.DELETE_OBJECT_FROM_LIST(index)
    },
    uploaded(file) {
      if (!file.status) return 'Upload starting soon...'
      if (file.status === 'uploading') return ` ${file.progress} %`
      if (file.status === 'encoding') return 'File encoding...'
      if (file.status === 'reenconding') return 'File re-encoding...'
      if (file.status === 'multipartComplete') return 'File upload complete...'
      if (file.status === 'success') return 'Completed'
      if (file.status === 'reencoded@success') return 'Completed'
      if (file.status === 'canceled') return 'Upload canceled!'
      if (file.status === 'failed') return 'Upload failed'
      if (file.status === 'encodeFailed') return 'Encoding failed'
      return ''
    },
    retry(u, i) {
      upload.retryUpload(u, i)
    },
    closeToast() {
      if (this.activeUploads > 0) {
        this.$bvModal.show(this.cancelUploadsWarningRef)
      } else this.destroyToast()
    },
    destroyToast() {
      this.$bvToast.hide(this.toastId)
      upload.destroyUploads()
    },
    removeUploadFromArray(u, i) {
      mutations.DELETE_OBJECT_FROM_LIST(i)
      if (this.uploads.length < 1) this.destroyToast()
      if (u.status === 'success') {
        if (u.fileType === 'video') {
          this.$router.push({ name: 'videos-edit-general-information', params: { projectId: this.$route.params.projectId, id: u.mediaId } })
        } else {
          this.$router.push({ name: 'audios-general-information', params: { projectId: this.$route.params.projectId, id: u.mediaId } })
        }
      }
    },
    async reloadPage() {
      this.reload = true
    },
  },
}
</script>

<style lang="scss" scoped>
#uploads-toast.toast.toast-closed {
  border-radius: calc(0.358rem - 1px);
  overflow: hidden;
  max-width: 240px;
}
.list-group .list-group-item:hover {
  background-color: #f6f7f9;
  z-index: 0;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto;
  max-height: 300px;
}
.custom-dimensions {
    border-radius: 6px;
    background: #EBEBED;
    width: 50px;
    min-width: 50px;
    height: 50px;

}
</style>
