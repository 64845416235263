import LiveVideo from '@/models/live-videos/LiveVideo'

export default {
  isLoading: true,
  liveVideo: new LiveVideo(),
  liveVideos: [],
  liveVideoCustomScreens: [],
  customScreensSelectList: [],
  // Analytics
  loadingAnalytics: false,
  loadingTopCountries: false,
  loadingTopDevices: false,
  loadingLiveViewers: false,
  diffInTimeCustomDate: null,
  loadingTopBrowsers: false,
  loadingCustomAnalytics: false,
  loadingTotalClips: false,
  customDate: [],
  dateStr: '',
  videoAnalyticsTotal: 0,
  liveViewersTotal: 0,
  topDevices: [],
  topBrowsers: [],
  totalClips: [],
  totalClipsPagination: {},
  topBrowsersGroupedData: {},
  topCountriesGroupedData: {},
  topDevicesGroupedData: {},
  liveViewersGroupedData: {},
  videoAnalytics: [],
  videoAnalyticsGropuedData: {},
  secureLink: '',

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  filters: {
    params: {
      page: 1,
      take: 10,
      search: '',
      author: null,
      order: 'desc',
    },
  },
  loadingAnalyticsReport: false,
  analyticsReport: null,
  loadingPlayer: false,
}
